import React from "react";
import useLang from "../../contexts/Language/LanguageContext";
import {text} from "./InternalOrg.module.scss";
import mima from "../../images/Mimasmall.jpg";
import translate from "../../utils/lang/langHash";

export default function InternalOrg() {
    const lang = useLang();
    return (
        <>
        <p className={text}>{translate("internal1", lang)}</p>
        <p className={text}>{translate("internal11", lang)}</p>
        <p className={text}>{translate("internal2", lang)}</p>
        <ul>
         <li className={text}>{translate("internalli1", lang)}</li>
         <li className={text}>{translate("internalli2", lang)}</li>
         <li className={text}>{translate("internalli3", lang)}</li>
         <li className={text}>{translate("internalli4", lang)}</li>
         <li className={text}>{translate("internalli5", lang)}</li>
         <li className={text}>{translate("internalli6", lang)}</li>
         <li className={text}>{translate("internalli7", lang)}</li>
         <li className={text}>{translate("internalli8", lang)}</li>
         <li className={text}>{translate("internalli9", lang)}</li>
         <li className={text}>{translate("internalli10", lang)}</li>
        </ul>
        <p className={text}>{translate("internal3", lang)}</p>
        <p className={text}>{translate("internal4", lang)}</p>
        <ul>
         <li className={text}>{translate("internalsli1", lang)}</li>
         <li className={text}>{translate("internalsli2", lang)}</li>
         <li className={text}>{translate("internalsli3", lang)}</li>
         <li className={text}>{translate("internalsli4", lang)}</li>
         <li className={text}>{translate("internalsli5", lang)}</li>
         <li className={text}>{translate("internalsli6", lang)}</li>
         <li className={text}>{translate("internalsli7", lang)}</li>
         <li className={text}>{translate("internalsli8", lang)}</li>
         <li className={text}>{translate("internalsli9", lang)}</li>
        </ul>
        <p className={text}>{translate("internal5", lang)}</p>
        <p className={text}>{translate("internal6", lang)}</p>
        <ul>
         <li className={text}>{translate("internaldli1", lang)}</li>
         <li className={text}>{translate("internaldli2", lang)}</li>
         <li className={text}>{translate("internaldli3", lang)}</li>
         <li className={text}>{translate("internaldli4", lang)}</li>
         <li className={text}>{translate("internaldli5", lang)}</li>
         <li className={text}>{translate("internaldli6", lang)}</li>
         <li className={text}>{translate("internaldli7", lang)}</li>
        </ul>
        </>
    )
}