import React, { useState } from "react";
import { text, heading } from "./AboutUs.module.scss";
import useLang from "../../contexts/Language/LanguageContext";
import translate from "../../utils/lang/langHash";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Team from "../../components/team/Team";
import InternalOrg from "../../components/internal-org/InternalOrg";
import useIsMobile from "../../utils/is-mobile/isMobile";
import Founders from "../../components/founders/Founders";

function AboutUs() {
  const lang = useLang();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobile = useIsMobile();
  return (
    <>
      <Tabs
        orientation={isMobile ? "vertical" : "horizontal"}
        value={value}
        onChange={handleChange}
        aria-label="odaberite link"
        variant="scrollable"
        scrollButtons="on"
      >
        <Tab
          id="about-tab"
          aria-controls="about-about-us"
          style={{ color: "white" }}
          label={translate("about", lang)}
        />
        <Tab
          id="founders-tab"
          aria-controls="about-founders"
          style={{ color: "white" }}
          label={lang === "mne" ? "Osnivači" : "Founders"}
        />
        <Tab
          id="internal-tab"
          aria-controls="about-internal"
          style={{ color: "white" }}
          label={
            lang === "mne" ? "Unutrašnja organizacija" : "Internal organization"
          }
        />
        <Tab
          id="team-tab"
          aria-controls="about-team"
          style={{ color: "white" }}
          label={lang === "mne" ? "Tim I MI Boke" : "Team IYDB"}
        />
      </Tabs>
      {value === 0 && (
        <div aria-labelledby="about-tab" id="about-about-us" className={text}>
          <p>{translate("about1", lang)}</p>
          <p>{translate("about2", lang)}</p>
          <p>{translate("about3", lang)}</p>
          <ul>
            <li>{translate("aboutli1", lang)}</li>
            <li>{translate("aboutli2", lang)}</li>
            <li>{translate("aboutli3", lang)}</li>
            <li>{translate("aboutli4", lang)}</li>
            <li>{translate("aboutli5", lang)}</li>
          </ul>
          <p>{translate("about4", lang)}</p>
        </div>
      )}
      {value === 1 && (
        <div aria-labelledby="founders-tab" id="about-founders">
          <Founders />
        </div>
      )}
      {value === 2 && (
        <div aria-labelledby="internal-tab" id="about-internal">
          <InternalOrg />
        </div>
      )}
      {value === 3 && (
        <div aria-labelledby="team-tab" id="about-team">
          <Team />
        </div>
      )}
    </>
  );
}

export default AboutUs;
