import React from "react";
import useLang from "../../contexts/Language/LanguageContext";
import { text } from "./Founders.module.scss";
import translate from "../../utils/lang/langHash";
import mima from "../../images/Mimasmall.jpg";
import andrija from "../../images/Andrijasquare.jpg";
import dunja from "../../images/Dunjasquare.jpg";

const founders = [
  {
    name: "Andrija Samardžić",
    image: andrija,
  },
  {
    name: "Dunja Samardžić",
    image: dunja,
  },
  {
    name: "Miroslava-Mima Ivanović",
    image: mima,
  },
];

export default function Founders() {
  const lang = useLang();
  return (
    <>
      <p>{translate("founders1", lang)}</p>
      <div role="list">
        {founders.map((f) => {
          return (
            <div
              key={f.name}
              role="listitem"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0px",
              }}
            >
              <img
                alt={f.name + " " + "slika"}
                style={{
                  objectFit: "fill",
                  border: "2px solid #00adee",
                  width: "70px",
                  height: "70px",
                  borderRadius: "200px",
                }}
                src={f.image}
              />
              <p
                style={{
                  fontSize: "1rem",
                  color: "white",
                  marginLeft: "10px",
                  textTransform: "none",
                }}
              >
                {f.name}
              </p>
            </div>
          );
        })}
      </div>
      <p>{translate("founders2", lang)}</p>
      <p>{translate("founders3", lang)}</p>
    </>
  );
}
